

import React, { useEffect } from "react";
import "./style.scss";
import { Form, Input, DatePicker, Select, Spin } from "antd";
import dayjs from "dayjs";

const { Option } = Select;

const ViewPartner = ({ client, loading }) => {

    console.log(client);
    const [form] = Form.useForm(); // Create form instance

    const {
        name = "",
        client_type = "",
        launch_date = "",
        address = "",
        phones = [],
        emails = [],
    } = client || {};

    const phone = phones.length > 0 ? phones[0]?.number || "N/A" : "N/A";
    const email = emails.length > 0 ? emails[0]?.email || "N/A" : "N/A";
   
    useEffect(() => {
        if (client) {
            form.setFieldsValue({
                name,
                client_type,
                launch_date: launch_date ? dayjs(launch_date) : null,
               address,
                phone,
                email,
            });
        }
    }, [client, form]);

    return (
        <Spin spinning={loading}>
            <Form
                className="view-partner-form"
                layout="vertical"
                form={form} // Bind form instance to the Form
            >
                <div className="basic-information">
                    <div className="title">Basic Information</div>
                    <div className="flex align-items-center justify-content-between gap-2">
                        <Form.Item label="Name" name="name">
                            <Input placeholder="Enter name" disabled />
                        </Form.Item>
                        <Form.Item label="Contact No." name="phone">
                            <Input placeholder="Enter contact number" disabled />
                        </Form.Item>
                        <Form.Item label="Client Type" name="client_type">
                            <Select placeholder="Select" disabled>
                                <Option value="B2B">B2B</Option>
                                <Option value="Franchise">Franchise</Option>
                                <Option value="qsr">QSR</Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <Form.Item label="Date of Launch" name="launch_date">
                        <DatePicker placeholder="Select date" disabled />
                    </Form.Item>
                </div>
                <div className="address-information">
                    <div className="title">Address</div>
            <Form.Item label="Address" name="address">
        <Input placeholder="Enter address" disabled />
        </Form.Item>
                    
                </div>
            </Form>
        </Spin>
    );
};

export default ViewPartner;
