import api from './api';

export const deleteClient = async (id) =>{

    console.log(id);
    try {
      const response = await api.delete(`/partners/${id}`);
      return response.data;
    } catch (error) {
      console.error('Failed to delete client detail:', error);
      throw new Error('Failed to delete client detail');
    }
  }