import React, { createContext, useState, useEffect, useContext } from "react";
import * as getServices from "../services/get";
import * as postServices from "../services/post";
import { AuthContext } from "./AuthContext";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const { authToken } = useContext(AuthContext);

  const [items, setItems] = useState([]);
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("cartItems")) || []
  );
  const [totalPrice, setTotalPrice] = useState(
    parseFloat(localStorage.getItem("totalPrice")) || 0
  );
  const [inProgressOrdersCount, setInProgressOrdersCount] = useState(0);
  const [selectedQuantities, setSelectedQuantities] = useState(() => {
    const storedQuantities = localStorage.getItem("selectedQuantities");
    return storedQuantities ? JSON.parse(storedQuantities) : {};
  });
  const [orders, setOrders] = useState([]);
  const [walletBalance, setWalletBalance] = useState(0);
  const [keywordList, setKeywordList] = useState([]);

  useEffect(() => {
    if (authToken) {
      fetchInProgressOrdersCount();
      handleFetchAllKeyword();
      handleFetchAllItems();
    }
  }, [authToken]);

  const fetchInProgressOrdersCount = async () => {
    const response = await getServices.fetchInProgressOrders();
    setInProgressOrdersCount(response);
  };

  const handleFetchAllItems = async () => {
    try {
      const fetchedItems = await getServices.fetchAllItems();
      setItems(fetchedItems);
    } catch (error) {
      console.error("Failed to fetch all items:", error);
    }
  };

  const updateCartItems = (itemId, quantity) => {
    const updatedItem = items.find((item) => item.id === itemId);
    //setItems(updatedItems);
    updatedItem.quantity = quantity;
    const existingItem = cartItems.find((item) => item.id === itemId);
    let newCartItems;
    if (!existingItem) {
      if (quantity === 0) {
        return; 
      }
       newCartItems = [...cartItems, updatedItem];
    }
    else{
      if (!quantity || quantity === 0) {
        newCartItems = cartItems.filter((item) => item.id !== itemId);
      }
      else{
      newCartItems = cartItems.map((item) =>
        item.id === itemId ? updatedItem : item
      );
    }
    }
    // const newCartItems = updatedItems.filter((item) => item.quantity > 0);
    setCartItems(newCartItems);
    const newTotalPrice = newCartItems.reduce(
      (sum, item) => sum + item.price * (item.quantity || 0),
      0
    );
    setTotalPrice(newTotalPrice);
    setSelectedQuantities((prev) => ({ ...prev, [itemId]: quantity }));
  };

  const clearCart = async () => {
    localStorage.removeItem("selectedQuantities");
    localStorage.removeItem("totalPrice");
    localStorage.removeItem("cartItems");
    setItems([]);
    localStorage.removeItem("items");
    setSelectedQuantities({});
    setTotalPrice(0);
    setCartItems([]);
    setItems([]); 
    console.log(cartItems);
    await handleFetchAllItems();
  };

  const handlePlaceOrder = async (orderDetails) => {
    try {
      const response = await postServices.placeOrder(orderDetails);
      clearCart();
      return response;
    } catch (error) {
      console.error("Failed to place order:", error);
      throw error;
    }
  };

  const handleFetchPartnerOrdersByStatus = async (
    status,
    page = 1,
    limit = 100
  ) => {
    try {
      const response = await getServices.fetchPartnerOrdersByStatus(
        status,
        page,
        limit
      );
      setOrders(response);
      return response;
    } catch (error) {
      console.error("Failed to fetch partner orders by status:", error);
      throw error;
    }
  };

  const handleFetchAllKeyword = async () => {
    try {
      const response = await getServices.fetchAllKeyword();
      setKeywordList(response);
      return response;
    } catch (error) {
      console.error("Failed to fetch all Keyword:", error);
      throw error;
    }
  };


  useEffect(() => {
    localStorage.setItem("totalPrice", totalPrice.toString());
  }, [totalPrice]);

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    const validQuantities = Object.fromEntries(
      Object.entries(selectedQuantities).filter(([_, value]) => value !== null)
    );
    localStorage.setItem("selectedQuantities", JSON.stringify(validQuantities));
  }, [selectedQuantities]);

  return (
    <AppContext.Provider
      value={{
        items,
        cartItems,
        setCartItems,
        totalPrice,
        setTotalPrice,
        selectedQuantities,
        setSelectedQuantities,
        updateCartItems,
        clearCart,
        handlePlaceOrder,
        handleFetchPartnerOrdersByStatus,
        orders,
        walletBalance,
        inProgressOrdersCount,
        keywordList,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};


