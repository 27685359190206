
import api from './api';


export const updateOrderPaymentStatus = async (orderId, payload) => {
  try {
    const response = await api.put(`/orders/payment/${orderId}`, payload);
    return response.data;
  } catch (error) {
    console.error('Error placing order:', error);
    throw new Error('Failed to place order');
  }
};

export const setDefaultAddress = async (addressId) => {
  try {
    const response = await api.put(`/address/default-address/${addressId}`);
    return response.data;
  } catch (error) {
    console.error('Error change default address:', error);
    throw new Error('Failed to change default address');
  }
};


export const deleteAddress = async (addressId) => {
  try {
    const response = await api.put(`/address/delete-address/${addressId}`);
    return response.data;
  } catch (error) {
    console.error('Error to remove address:', error);
    throw new Error('Failed to remove address');
  }
};



export const updateOrderStatusAPI = async (orderId, status) => {
  try {
    const response = await api.put(`/orders/update-status/${orderId}`, {
      status, // Axios handles the JSON stringification internally
    });
    return response.data; // Accessing the data directly from the Axios response
  } catch (error) {
    console.error('Error updating order status:', error);
    throw error;
  }
};


export const updatePackOrder = async (orderId, payload) => {
  try {
    const response = await api.put(`/orders/pack-order/${orderId}`, payload);
    return response.data;
  } catch (error) {
    console.error('Error Packing order:', error);
    throw new Error('Failed to order Packaging');
  }
};

export const updateDispatchOrder = async (orderId, payload) => {
  try {
    const response = await api.put(`/orders/dispatch-order/${orderId}`, payload);
    return response.data;
  } catch (error) {
    console.error('Error Packing order:', error);
    throw new Error('Failed to order Packaging');
  }
};

export const updatePartnerPassword = async (partnerId, payload) => {
  try {
    const response = await api.put(`/auth/update-password/${partnerId}`, payload);
    return response.data;
  } catch (error) {
    console.error('Error updating partner password:', error);
    throw new Error('Failed to update partner password');
  }
};


export const updateClientComplaint = async (id, payload) => {
  try {
    const response = await api.patch(`/complaints/${id}/status`, payload);
    return response.data;
  } catch (error) {
    console.error('Error updating client complaint:', error);
    throw new Error('Failed to update client complaint');
  }
};



export const updatePartnerPrices = async (partnerId, items) => {
  console.log("data", partnerId, items);
  try {
    const response = await api.put(`/items/partner-prices/${partnerId}`, items);
    return response.data;
  } catch (error) {
    console.error("Error updating partner item prices:", error.response?.data || error.message);
    throw error;
  }
};


