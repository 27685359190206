import { Avatar } from 'antd'
import React from 'react'

export const CustomAvatar = ({item}) => {

    const generateLabel = (fullName) => {
        if (!fullName) {
            return null; // or any default value like "?" when the name is missing
        }
        const nameParts = fullName.split(" ");
        const firstName = nameParts[0] || "";
        const lastName = nameParts[nameParts.length - 1] || "";
        return firstName.charAt(0) + lastName.charAt(0);
      };

  return (
    <Avatar className="avatar">
              {generateLabel(item?.partner?.name || item?.client_name)}
    </Avatar>
  )
}