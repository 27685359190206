import React, { createContext, useState } from "react";
// import { Avatar } from "antd";
import * as putServices from "../services/put";
import * as getServices from "../services/get";

export const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
  const [newOrders, setNewOrders] = useState([]);
  const [packagingOrders, setPackagingOrders] = useState([]);
  const [dispatchOrders, setDispatchOrders] = useState([]);
  const [outForDeliveredOrders, setOutForDeliveredOrders] = useState([]);
  const [deliveredOrders, setDeliveredOrders] = useState([]);
  const [expandedCard, setExpandedCard] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContentComponent, setModalContentComponent] = useState(null);
  const [modalActions, setModalActions] = useState([]);
  const [modalTitle, setModalTitle] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const handleCardClick = (orderId) => {
    setExpandedCard(expandedCard === orderId ? null : orderId);
  };



  // Open modal with loader
  const openModal = (title, component, actions) => {
    setIsLoading(true); // Show loader before modal opens
    setTimeout(() => {
      setModalTitle(title);
      setModalContentComponent(component);
      setModalActions(actions);
      setIsModalOpen(true);
      setIsLoading(false); // Hide loader when modal is ready
    }, 500); // Simulate delay for modal preparation
  };


   // Fetch orders with specific status codes
   const fetchOrders = async (status, setter, page = 1, limit = 1000) => {
    setIsLoading(true); // Show loader during API call
    try {
      const response = await getServices.getOrdersByStatus(status, page, limit );
      setter(response);
    } catch (error) {
      console.error(`Failed to fetch orders with status ${status}:`, error);
    } finally {
      setIsLoading(false); // Hide loader after API call
    }
  };

  
  const fetchNewOrders = () => fetchOrders(20, setNewOrders);
  const fetchPackagingOrders = () => fetchOrders(25, setPackagingOrders);
  const fetchDispatchOrders = () => fetchOrders(30, setDispatchOrders);
  const fetchOutForDeliveredOrders = () => fetchOrders(90, setOutForDeliveredOrders);
  const fetchDeliveredOrders = () => fetchOrders(100, setDeliveredOrders);

  // Update the order status and re-fetch both new and delivered orders after a 5-second delay
  const updateOrderStatus = async (orderId, newStatus) => {
    setIsLoading(true); // Show loader during API call
    try {
      const data = await putServices.updateOrderStatusAPI(orderId, Number(newStatus));
      return data;
    } catch (error) {
      console.error('Failed to update order status:', error);
      throw error;
    }
    finally {
      setIsLoading(false); // Hide loader after API call
    }
  };

  const searchOrders = (query, orders) => {
    if (!query) return orders; // If no query, return the original orders
    const lowerCaseQuery = query.toLowerCase();
    return orders.filter(order => {
      const name = order?.partner?.name?.toLowerCase() || ''; // Safely handle undefined or null
      const id = order?.id?.toString() || '';        // Safely handle undefined or null
      const location = order?.deliveryAddress?.city?.toLowerCase() || ''; // Safely handle undefined or null
      return (
        name.includes(lowerCaseQuery) ||
        id.includes(lowerCaseQuery) ||
        location.includes(lowerCaseQuery)
      );
    });
  };

  return (
    <OrderContext.Provider
      value={{
        expandedCard,
        handleCardClick,
        isModalOpen,
        setIsModalOpen,
        modalTitle,
        modalContentComponent,
        modalActions,
        openModal,
        updateOrderStatus,
        updateOrderStatus,
        newOrders,
        packagingOrders,
        dispatchOrders,
        outForDeliveredOrders,
        deliveredOrders,
        setNewOrders,
        setPackagingOrders,
        setDispatchOrders,
        setOutForDeliveredOrders,
        setDeliveredOrders,
        fetchNewOrders,
        fetchPackagingOrders,
        fetchDispatchOrders,
        fetchOutForDeliveredOrders,
        fetchDeliveredOrders,
        isLoading,
        searchOrders,
        isButtonDisabled, setIsButtonDisabled
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};
