import React from "react";
import "./Content.scss";
import { Layout } from "antd";
import Routers from "../../../Routers/Routers";

export const CustomContent = () => {
  const { Content } = Layout;
  return (
    <Content className="content">
        <Routers />
    </Content>
  );
};
