import api from './api';

export const login = async (email, password) => {
  try {
    const response = await api.post('/auth/login', { email, password });
    if (response.data === 'Invalid credentials') {
      throw new Error('Invalid credentials');
    }
    return response.data;
  } catch (error) {
    console.error('Failed to login:', error);
    throw new Error('Failed to login');
  }
};

export const placeOrder = async (orderDetails) => {
  try {
    const response = await api.post('/orders', orderDetails);
    return response.data;
  } catch (error) {
    console.error('Error placing order:', error);
    throw new Error('Failed to place order');
  }
};

export const placeOrderByAdmin = async (partnerId, orderDetails) => {
  try {
    const response = await api.post(`/orders/admin/place-order/${partnerId}`, orderDetails);
    return response.data;
  } catch (error) {
    console.error('Error placing order:', error);
    throw new Error('Failed to place order');
  }
};


export const addAddress = async (addressDetails) => {
  try {
    const response = await api.post('/address', addressDetails);
    return response.data;
  } catch (error) {
    console.error('Error adding address:', error);
    throw new Error('Failed to add address');
  }
}


export const addAddressByClientId = async (clientId, addressDetails) => {
  try {
    const response = await api.post(`/address/partner/${clientId}`, addressDetails);
    return response.data;
  } catch (error) {
    console.error('Error adding address by clientId:', error);
    throw new Error('Failed to add address');
  }
};


export const creditStock = async (itemId, quantity) => {
  try {
    const response = await api.post(`/stock/credit-stock/${itemId}`, { quantity });
    return response.data;
  } catch (error) {
    console.error(`Failed to credit stock for item ${itemId}:`, error);
    throw error;
  }
};


// export const updateMinimumStock = async (data) => {
//   try {
//     const response = await api.post('/stock/minimum-stock', data);
//     return response.data;
//   } catch (error) {
//     console.error('Failed to update minimum stock:', error);
//     throw error;
//   }
// };


export const updateMinimumStock = async (data) => {
  try {
    const response = await api.post('/stock/minimum-stock', data);
    return response.data;
  } catch (error) {
    console.error('Failed to update minimum stock:', error);
    throw error;
  }
};

// Function to update current stock by passing data to the API
export const updateStockMaster = async (data) => {
  try {
    const response = await api.post('/stock', data);
    return response.data;
  } catch (error) {
    console.error('Failed to update stock:', error);
    throw error;
  }
};


export const updateStockTransaction = async (transactionId, updatedQuantity, comments = null, editedFlag = false) => {
  try {
    const response = await api.post('/stock/update-stock-transaction', {
      id: transactionId,
      quantity: updatedQuantity,
      comments: comments || '',
      edited_flag: editedFlag,
    });
    return response.data;
  } catch (error) {
    console.error(`Failed to update stock transaction for ID ${transactionId}:`, error);
    throw error;
  }
};


export const updateLockStock = async (orderId, data) => {
  try {
    const response = await api.post(`/orders/admin/lock-stock/${orderId}`, data);
    return response.data;
  } catch (error) {
    console.error('Failed to update lock stock:', error);
    throw error;
  }
};

export const cancelOrder = async (orderId, comment) => {
  try {
    debugger;
    const response = await api.post(`orders/cancel-order/${orderId}`, { "comment": comment });
    return response.data;
  } catch (error) {
    console.error('Failed to cancel order:', error);
    throw error;
  }
};

export const addNewPartner = async (data) => {
  try {
    const response = await api.post('/partners', data);
    return response.data;
  } catch (error) {
    console.error('Failed to add partner:', error);
    throw error;
  }
};


export const addComplaint = async (complaintData) => {
  try {
    const response = await api.post('/complaints', complaintData);
    return response.data;
  } catch (error) {
    console.error('Error creating complaint:', error);
    throw error;
  }
}