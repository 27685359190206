import { Spin } from 'antd'
import React from 'react'
import "./Loader.scss";

export const CustomLoader = () => {
  return (
    <div className="loading-overlay">
          <Spin tip="Loading addresses..." />
        </div>
  )
}
