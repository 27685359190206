
import api from './api';

export const fetchAllItems = async () => {
  try {
    const response = await api.get('/items');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch items:', error);
    throw new Error('Failed to fetch items');
  }
};

export const fetchAllKeyword = async () => {
  try {
    const response = await api.get('/keyword-master/Category');
    return response.data;

  } catch (error) {
    console.error('Failed to fetch items:', error);
    throw new Error('Failed to fetch items');
  }
};

export const fetchPartnerOrdersByStatus = async (status, page = 1, limit = 10) => {
  try {
    const response = await api.get(`/orders/partner/${status}`, { params: { page, limit } });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch partner orders by status:', error);
    throw new Error('Failed to fetch partner orders by status');
  }
};

export const fetchAllAddresses = async () => {
  try {
    const response = await api.get('/address');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch addresses:', error);
    throw new Error('Failed to fetch addresses');
  }
}

export const fetchAddressesByClientId = async (clientId) => {
  try {
    const response = await api.get(`/address/partner/${clientId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch addresses by clientId:', error);
    throw new Error('Failed to fetch addresses by clientId');
  }
};

export const fetchInProgressOrders = async ()=>{
  try {
    const response = await api.get('/orders/inprogress-count');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch in progress orders:', error);
    throw new Error('Failed to fetch in progress orders');
  }
}


export const getAllOrders = async ( page = 1, limit = 10) => {
  try {
    const response = await api.get(`/orders/all-active`, { params: { page, limit } });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch orders by status:', error);
    throw new Error('Failed to fetch orders by status');
  }
};


export const getOrdersByStatus = async ( status, page = 1, limit = 100) => {
  try {
    const response = await api.get(`/orders/status/${status}`, { params: { page, limit } });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch partner orders by status:', error);
    throw new Error('Failed to fetch partner orders by status');
  }
};


export const fetchAllClient = async () => {
  try {
    const response = await api.get('/partners');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch client:', error);
    throw new Error('Failed to fetch client');
  }
};


export const fetchAllStocks = async () => {
  try {
    const response = await api.get('/stock');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch stocks:', error);
    throw new Error('Failed to fetch stocks');
  }
};


export const fetchStockTransactions = async (itemId) => {
  try {
    const response = await api.get(`/stock/transactions/${itemId}`); // Adjust the API endpoint URL as needed
    return await response.data; 
  } catch (error) {
    console.error("Error fetching stock transactions:", error);
    throw new Error('Failed to fetch stock transactions');
  }
};

export const fetchLockedStock = async () => {
  try {
    const response = await api.get('/stock/lock-stock-item-wise');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch locked stock:', error);
    throw new Error('Failed to fetch locked stock');
  }
}