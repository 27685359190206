import React, { useContext, useRef, useState, useEffect } from "react";
import "./style.scss";
import { CustomButton as Button } from "../../CommonComponents/store/button/CustomButton";
import { Toast } from "primereact/toast";
import { CustomLoader as Loader } from "../../CommonComponents/loader/Loader";
import { CustomBreadcrumb as Breadcrumb } from "../../CommonComponents/store/breadcrumb/Breadcrumb";
import { OrderContext } from "../../Context/OrderContext";
import { Form } from "antd";
import AddPartner from "../../Components/addPartner/AddPartner";
import ClientsTable from "../../Components/clientsTable/ClientsTable";
import { fetchClientsDetails } from "../../services/get";
import { addNewPartner } from "../../services/post";
import moment from "moment";
import { deleteClient } from "../../services/delete";

const AllPartner = () => {
  const { openModal, setIsModalOpen } = useContext(OrderContext);
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [clients, setClients] = useState(null);
  const [loading, setLoading] = useState(false);

  const toastRef = useRef(null);

  useEffect(() => {
    fetchClients();
    
  }, []);

  const fetchClients = async () => {
    fetchClientsDetails()
    .then((res) => {
      setClients(res);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const onFinish = (values) => {
    setLoading(true);
    setIsModalOpen(false);
    const formattedValues = {
        ...values,
        launch_date: values.launch_date ? moment(values.launch_date).set({hour: 14, minute: 0}).format("YYYY-MM-DD HH:mm")
          : null,
        email: values.avoid_auto_fill_email,
        password: values.avoid_auto_fill_password
      };
      addNewPartner(formattedValues)
      .then((res) => {
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: "Partner added successfully",
        });
        setLoading(false);
        fetchClients();
      })
      .catch((err) => {
        console.log(err);
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to add partner: " + err.message,
        });
        setLoading(false);
        setIsModalOpen(false);

      });
  };

  const deletePartnerHandler = (client_id) => {
    setLoading(true);
      deleteClient(client_id)
      .then((res) => {
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: "Client deleted successfully",
        });
        setLoading(false);
        fetchClients();
        
      })
      .catch((err) => {
        console.log(err);
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to delete partner: " + err.message,
        });
        
        setLoading(false);
      })
  }

  const addPartner = () => {
    form.resetFields();
    setLoading(false);
    openModal(
      <div className="modal-header">
        <div className="modal-title">Add Client Details</div>
      </div>,
      <AddPartner form={form} onFinish={onFinish} loading={loading} />,
       [
        {
          label: "Cancel",
          onClick: () => setIsModalOpen(false),
          customBtnClass: "secondBtn Btn",
        },
        {
          label: "Add",
          onClick: () => form.submit(),
          customBtnClass: "Btn firstBtn",
        },
      ]
    );
  };

  return (
    <>
      <Toast ref={toastRef} />
      <div className="main-section w-full h-full flex flex-column p-3">
        <div className="top-section flex align-items-center justify-content-between px-3">
          <div className="left-side">
            <Breadcrumb setTitle={setTitle} />
            <div className="main-title">{title}</div>
          </div>
          <div className="right-side flex align-items-center gap-3">
            {
              loading ? <Loader /> :
              <Button customBtnClass="Btn firstBtn" onClick={() => addPartner()}>
              + Add New Partner
            </Button>
            }
            
          </div>
        </div>
        <div className="table-container pt-3 flex-1 flex flex-column">
        {isLoading ? (
          <Loader /> // Show loader while data is being fetched
        ) : (
           <ClientsTable clients={clients} deletePartnerHandler={deletePartnerHandler} />
        )}
        </div>
      </div>
    </>
  );
};

export default AllPartner;
