

import React, { useState, useEffect, Fragment, useRef, useContext } from "react";
import { Table, Input, DatePicker, Select, message, Modal, Form } from "antd";
import moment from "moment";
import { updatePartnerPassword, updatePartnerPrices } from '../../services/put';
import InputBoxModal from '../inputbox-modal/inputbox-modal';
import dayjs from 'dayjs';
import "./style.scss";
import { CustomButton as Button } from "../../CommonComponents/store/button/CustomButton";
import ViewPartner from "../../Modal/viewPartner/ViewPartner";
import { OrderContext } from "../../Context/OrderContext";
import { fetchClientDetail, fetchItemsByPartnerId } from "../../services/get";
import viewIcon from "../../assets/images/view-icon.svg";
import editIcon from "../../assets/images/edit_icon-button.svg";
import deleteIcon from "../../assets/images/delete-icon.svg";
import rupeeIcon from "../../assets/images/rupee-icon.svg";
import { deleteClient } from "../../services/delete";
import { CustomAvatar } from "../../CommonComponents/avatar/Avatar";

const { Option } = Select;
const { RangePicker } = DatePicker;

const ClientsTable = ({ clients = [] }) => {
    const { openModal, setIsModalOpen } = useContext(OrderContext);
      const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [form] = Form.useForm();
  const [updatePasswordModal, setUpdatePasswordModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [viewClientData, setViewClientData] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [itemsData, setItemsData] = useState([]);

  const passwordRef = useRef(null);

  // Filter States
  const [clientName, setClientName] = useState("");
  const [city, setCity] = useState(null);
  const [launchDate, setLaunchDate] = useState(null);
  const [clientType, setClientType] = useState(null);

  // Extract cities from clients
  const cities = [...new Set(
    (clients || []).flatMap(client =>
      client.addresses?.map(addr => addr.city).filter(Boolean) || []
    )
  )];

  const clientTypes = [...new Set((clients || []).map(client => client.client_type).filter(Boolean) || [])];

  useEffect(() => {
    applyFilters();
  }, [clients, clientName, city, launchDate, clientType]);

  const onChangeLaunchDate = (date) => {
    setLaunchDate(date ? dayjs(date) : null);
  };

  const applyFilters = () => {
    const filtered = (clients || []).filter(client => {
      // Filter by client name
      const matchName = !clientName || client.name?.toLowerCase().includes(clientName.toLowerCase());
      const matchClientType = !clientType || client.client_type === clientType;
      
      const matchLaunchDate =
      !launchDate || dayjs(client.launch_date).isSame(launchDate, "day");
      
      // Filter by city
      const matchCity = !city || client.addresses?.some(addr => addr.city === city);

      return matchName && matchLaunchDate && matchCity;
    });

    setFilteredData(filtered);
  };

  const clearFilters = () => {
    setClientName("");
    setCity("");
    setClientType("");
    setLaunchDate(null);
    setFilteredData(clients || []);
  };

  const viewPartner = async (id) => {
    setLoading(true); // Start loading state
    try {
        const response = await fetchClientDetail(id); // Fetch client details
        setViewClientData(response); // Update client data state
        // setIsModalOpen(true); // Open the modal after data is fetched
      openModal(
      <div className="modal-header">
        <div className="modal-title">View Client Details</div>
      </div>,
      <ViewPartner client={response} loading={loading} />,
    );
    } catch (error) {
        message.error("Failed to fetch client details");
        console.error(error);
    } finally {
        setLoading(false); // Stop loading state
    }
};


const updateItemPrice = async (record) => {
  setLoading(true);
  try {
    const data = await fetchItemsByPartnerId(record.id);
    const formattedData = data.map(item => ({
      ...item,
      updatedPrice: item.price, // Initialize updatedPrice
    }));
    setSelectedClient(record);
    setItemsData(formattedData); // Update state
    setTimeout(() => {
      openPriceUpdateModal(formattedData, record);
    }, 0);
  } catch (error) {
    message.error("Failed to fetch items");
  } finally {
    setLoading(false);
  }
};


const itemListColumns = [
  {
    title: "Item Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Price",
    dataIndex: "updatedPrice",
    key: "price",
    render: (_, record) => (
      <Form.Item
        name={["items", record.id, "updatedPrice"]}
        initialValue={record.updatedPrice}
      >
        <Input
          type="text"
          onChange={(e) => handlePriceChange(e, record)}
          className="price-input"
        />
      </Form.Item>
    ),
  },
];

const handlePriceChange = (event, record) => {
  const { value } = event.target;
  form.setFieldsValue({
    items: {
      ...form.getFieldValue("items"),
      [record.id]: { updatedPrice: value },
    },
  });
};


// const handleSaveUpdatedPrices = async (clientRecord) => {
//   try {
//     const updatedValues = form.getFieldsValue().items; // Extract updated form data
//     console.log("Updated Values:", updatedValues);

//     // Prepare the correct payload for the API
//     const updatedItems = itemsData
//       .map((item) => ({
//         itemId: item.id, // Ensure correct field name
//         price: updatedValues[item.id]?.updatedPrice || item.price, // Preserve existing price if not updated
//       }))
//       .filter((item) => item.price !== undefined); // Remove undefined values

//     console.log("Final Data to Send:", updatedItems);
//     console.log("Client Record:", clientRecord);

//     if (updatedItems.length === 0) {
//       message.warning("No changes detected.");
//       return;
//     }

//     setLoading(true);
//     await updatePartnerPrices(clientRecord.id, updatedItems);
//     message.success("Prices updated successfully!");
//     setIsModalOpen(false);
//   } catch (error) {
//     console.error("Error Updating Prices:", error);
//     message.error("Failed to update prices");
//   } finally {
//     setLoading(false);
//   }
// };

// Open modal with Form



// Fix for the handleSaveUpdatedPrices function


const handleSaveUpdatedPrices = async (clientRecord) => {
  try {
    const formValues = form.getFieldsValue().items; // Extract updated form data
    
    if (!formValues) {
      message.warning("No changes detected.");
      return;
    }

    // Prepare the correct payload for the API
    const updatedItems = Object.entries(formValues).map(([itemId, itemData]) => {
      // Check if itemData exists before accessing updatedPrice
      if (!itemData || itemData.updatedPrice === undefined) {
        return null; // Skip this item if data is invalid
      }
      
      return {
        itemId: parseInt(itemId), // Ensure itemId is a number
        price: parseFloat(itemData.updatedPrice) // Ensure price is a number
      };
    }).filter(item => item !== null && !isNaN(item.price)); // Remove invalid entries

    if (updatedItems.length === 0) {
      message.warning("No valid price changes detected.");
      return;
    }

    setLoading(true);
    await updatePartnerPrices(clientRecord.id, {items: updatedItems});
    message.success("Prices updated successfully!");
    setIsModalOpen(false);
  } catch (error) {
    console.error("Error Updating Prices:", error);
    message.error("Failed to update prices: " + (error.message || "Unknown error"));
  } finally {
    setLoading(false);
  }
};


const openPriceUpdateModal = (items, clientRecord) => {
  console.log("clientRecord", clientRecord);
  form.setFieldsValue({
    items: Object.fromEntries(items.map((item) => [item.id, { updatedPrice: item.updatedPrice }])),
  });

  openModal(
    // <div className="modal-header">
    //   <div className="modal-title">Update Price of Items</div>
    // </div>,
     <div className="modal-header">
            <div className="modal-title">Update Price of Items</div>
            <div className="flex align-items-center gap-3">
              <div className="flex align-items-center gap-2">
                <span className="header-text">Client:</span>
                <span className="flex align-items-center gap-2">
                  {/* <CustomAvatar item={data} /> */}
                  <div className="header-client_name">{clientRecord?.name}</div>
                </span>
              </div>
              <div className="divider-line"></div>
              <div className="flex align-items-center gap-2">
                <span className="header-text">Location:</span>
                <div className="header-location">{clientRecord?.address}</div>
              </div>
            </div>
          </div>,
    <Form form={form} layout="vertical" className="client-item-table-form">
      <Table
        columns={itemListColumns}
        dataSource={items}
        pagination={false}
        rowKey="id"
        className="client-item-table"
      />
    </Form>,
    [
      {
        label: "Cancel",
        onClick: () => setIsModalOpen(false),
        customBtnClass: "secondBtn Btn",
      },
      {
        label: "Save",
        onClick: () => handleSaveUpdatedPrices(clientRecord),
        customBtnClass: "Btn firstBtn",
      },
    ]
  );
};







const confirmDelete = (record) => {
Modal.confirm({
  title: "Are you sure you want to delete this client?",
  icon: <div className="delete-icon">
    <img src={deleteIcon} alt="delete"   />
  </div>, // Custom icon
  content: (
    <div>
      <div>
       Client Name: <span>{record.name}</span>
      </div>
      <div >
       Location: <span>{record.address}</span>
      </div>
    </div>
  ),
  footer: (
    <div className="custom-footer">
      <Button
        onClick={() => Modal.destroyAll()} // Close modal on cancel
        customBtnClass="Btn secondBtn"
      >
        Cancel
      </Button>
      <Button
        customBtnClass="Btn firstBtn"
        onClick={() => handleDeleteClient(record.id)} // Call delete handler
      >
        Delete
      </Button>
    </div>
  ),
  closable: true,
  className: "custom-modal",
});
};

const handleDeleteClient = async (id) => {
  setDeleteLoading(true);
  try {
      await deleteClient(id);
      message.success("Client deleted successfully");
      setFilteredData((prev) => prev.filter((client) => client.id !== id)); // Update client list
  } catch (error) {
      message.error("Failed to delete client");
  } finally {
      setDeleteLoading(false);
  }
};

const handleUpdatePassword = (record) => {
  setUpdatePasswordModal(true);
  setSelectedClient(record);
}

    const handleDeletePartnerSubmit = (value) => {
      if(value === selectedClient?.name.split(" ")[0].toLowerCase()){
        
      }
      else{
        message.error("Partner name does not match");
      }
    }

const handleUpdatePasswordSubmit = (value) => {
  setUpdatePasswordModal(false);
  updatePartnerPassword(selectedClient.id, { password: value })
    .then((res) => {
      message.success("Password updated successfully");
    })
    .catch((err) => {
      message.error("Failed to update password");
      setUpdatePasswordModal(false);
    })
}

  const columns = [
    {
      title: "Client Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name), // Sorting by client name
    },
    {
      title: "Client Type",
      width: 200,
      dataIndex: "client_type",
      key: "client_type",
      sorter: (a, b) => a.client_type.localeCompare(b.client_type),
    },
    {
      title: "Email ID",
      dataIndex: "email",
      key: "email",
      render: (_, record) => {
        if (record.users && record.users.length > 0) {
          return record.users[0].email;
        }
        return "N/A";
      }
    },
    {
      title: "Mobile NO.",
      render: (_, record) => {
        if (record.phones && record.phones.length > 0) {
          return record.phones[0].number;
        }
        return "N/A";
      }
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      render: (_, record) => {
        if (record.addresses && record.addresses.length > 0) {
          return record.addresses[0].city;
        }
        return "N/A";
      },
      sorter: (a, b) => {
        const cityA = a.addresses?.[0]?.city || '';
        const cityB = b.addresses?.[0]?.city || '';
        return cityA.localeCompare(cityB);
      }
    },
    {
      title: "Launch Date",
      dataIndex: "launch_date",
      key: "launch_date",
      render: (_, record) => {
        if (record.launch_date) {
          return moment(record.launch_date).format("DD-MM-YYYY");
        }
        return "N/A";
      },
      sorter: (a, b) => moment(a.launch_date).unix() - moment(b.launch_date).unix(), // Sorting by launch date
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      width: 200,
      render: (_, record) => {
        return <div className="flex align-items-center justify-content-end gap-2">
<Button onClick={() => updateItemPrice(record)} customBtnClass="icon-btn">
          <img src={rupeeIcon} alt="rupee-icon" />
        </Button>
<Button onClick={() => viewPartner(record?.id)} customBtnClass="icon-btn">
          <img src={viewIcon} alt="view-icon" />
        </Button>
          
        <Button onClick={() => handleUpdatePassword(record)} customBtnClass="icon-btn">
          <img src={editIcon} alt="edit-icon" />
        </Button>
       
        <Button  onClick={() => confirmDelete(record)} customBtnClass="icon-btn" loading={deleteLoading}>
          <img src={deleteIcon} alt="delete-icon" />
        </Button>
        </div>;
      }
    },
  ];



  return (
    <Fragment>
      <InputBoxModal
        visible={updatePasswordModal}
        onCancel={() => setUpdatePasswordModal(false)}
        onSubmit={handleUpdatePasswordSubmit}
        title="Update Password"
      />
      <div className='all-client-container flex-1 flex flex-column'>
        <div className="filter_section flex align-items-center justify-content-between p-2 mb-2 gap-5">
          <div className="flex align-items-center gap-3">
            <div className="filter_by_client_name">
              <div className="title">Client Name</div>
              <Input className="input" placeholder="Name" value={clientName} onChange={e => setClientName(e.target.value)} />
            </div>
            <div className="filter_by_client_type">
              <div className="title">Client Type</div>
              <Select
                className="select"
                placeholder="Select Client Type"
                value={clientType}
                onChange={value => setClientType(value)}
                allowClear
              >
                {clientTypes.map((type) => (
                  <Option key={type} value={type}>
                    {type}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="filter_by_launch_date">
              <div className="title">Launch Date</div>
              <DatePicker placeholder="Select" value={launchDate} onChange={onChangeLaunchDate} />
            </div>
            <div className="filter_by_location">
              <div className="title">City</div>
              <Select
                className="select"
                placeholder="Select City"
                value={city}
                onChange={value => setCity(value)}
                allowClear
              >
                {cities.map((city) => (
                  <Option key={city} value={city}>
                    {city}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="flex align-items-center gap-2 ">
            {/* <Button onClick={applyFilters} customBtnClass="firstBtn Btn">Apply Filter</Button> */}
            <Button onClick={clearFilters} customBtnClass="secondBtn Btn">Clear All</Button>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={false}
          rowKey="id"
          className="client-table"
          sticky
          scroll={{ x: 'max-content' }}
          loading={clients === null}
        />
      </div>
      
    </Fragment>
  );
}

export default ClientsTable;
